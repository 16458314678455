.primary-indicator {
	width: 2rem;
	margin-left: 3px;
	border-radius: .2rem;
	text-align: center;
	line-height: 1.8rem;
	font-size: 1rem;
	color: darkgreen;
	background-color: lightgreen;
	border: solid 1px rgba(0,0,0,0.1);
}
