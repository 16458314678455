.me-field {

	width: 100%;
	padding: 0;

	.field-question {
		display: inline-block;
		width: auto;
		position: relative;
		padding: .5em 1em;
		background: $page-background-darker;
		color: black;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		border: rgba(0,0,0,0.08) solid 1px;
		margin-bottom: 1em;
		margin-left: .8em;
	}

	.field-question:after {
		content: '';
		position: absolute;
		border-style: solid;
		border-width: 10px 15px 10px 0;
		border-color: transparent $page-background-darker;
		display: block;
		width: 0;
		z-index: 1;
		margin-top: -10px;
		left: -15px;
		top: 1.2em;
	}

	.field-question:before {
		content: '';
		position: absolute;
		border-style: solid;
		border-width: 10px 15px 10px 0;
		border-color: transparent rgba(0,0,0,0.12);
		display: block;
		width: 0;
		z-index: 0;
		margin-top: -10px;
		left: -16px;
		top: 1.2em;
	}

	> * {
		display: block;
		width: 100%;

		.k-label {
			margin-bottom: 0;
			margin-left: 3px;
			font-size: .9em;
			color: rgba(0,0,0,0.7);

			&:hover:not(:focus-within) {
				cursor: pointer;
			}
		}

		.optional-field {
			font-size: .8em;
			margin-left: 1em;
			font-style: italic;
		}

		&.input-wrapper {
			transition: background-color .7s;
			background-color: rgba(0,0,0,0.01);
			border: solid 1px rgba(0,0,0,0.03);
			padding: 3px;

			.k-datepicker,
			.k-dropdown,
			input {
				width: 100%;

				&.has-error {
					border-color: darkred;

					.k-dropdown-wrap,
					.k-picker-wrap {
						border-color: darkred;
					}
				}
			}

			pre {
				margin: 0;
				color: rgb(51,51,51);
			}

			pre.readonly-field-pre {
				white-space: pre-wrap;
				font-family: $font-family;
				color: #000;
				background-color: #fbfbfb;
				min-height: 2em;
				padding: .3em;
				font-size: .9em;
			}

		}

		&.field-indicators {
			margin-left: 3px;
			display: grid;
			grid-gap: 3px;
			grid-template-columns: repeat(2, auto);
			font-size: .8em;
			min-height: 2em;

			> .error-indicator > div {
				color: darkred;
				background: linear-gradient(45deg, rgb(255,255,0,0.1), transparent);

				i {
					margin-right: .25em;
				}
			}

			> .status-indicators > div {
				padding-right: 4px;
				text-align: right;

				&.field-pending {
					color: rgba(0,0,0,0.7);
				}

				&.field-saving {
					.fa-spin {
						margin-right: .25em;
					}
				}

				&.field-saved {
					background: linear-gradient(45deg, transparent, rgb(0,255,0,0.1));
				}

				&.field-no-auth {
					background: linear-gradient(45deg, transparent, rgb(255,0,0,0.1));
				}

				&.field-changed {
					background: linear-gradient(45deg, transparent, rgb(255,255,0,0.1));
				}
			}
		}
	}

	&:focus-within {
		.input-wrapper {
			background-color: rgba(0,0,0,0.04);
		}
	}

	&:hover:not(:focus-within) {
		cursor: pointer;

		.input-wrapper {
			background-color: rgba(0,0,0,0.03);
		}
	}

	&.incomplete-field {
		background-color: rgba(255, 225, 0, 0.07);
		border: solid 1px rgba(0,0,0,0.07);
	}
}

readonly-field * {
	cursor: default !important;
}
