.k-switch {

	.k-switch-track {
		height: 20px;
	}

	.k-switch-thumb {
		width: 30px;
		height: 25px;
	}

	.k-switch-label-off {
		right: 8px;
		top: 10px;
	}

	.k-switch-label-on {
		left: 8px;
		top: 10px;
	}

	&.off-red {
		&.k-switch-off .k-switch-container {
			color: white;
			background-color: darkred;
		}
	}

	&.yes-no-switch {
		width: 6em;

		.k-switch-container {
			.k-switch-label-on {
				width: 6em;
				// padding-left: 1em;
			}

			.k-switch-label-off {
				width: 6em;
				// padding-right: 1em;
			}
		}
	}
}