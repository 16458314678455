/**** Breakpoints (Constants in layout-constants.ts must match these) ****/

$breakpoint-XS-max: 599.9px;
$breakpoint-SM-min: 600px;
$breakpoint-SM-max: 999.9px;
$breakpoint-MD-min: 1000px;
$breakpoint-MD-max: 1199.9px;
$breakpoint-LG-min: 1200px;
$breakpoint-LG-max: 1599.9px;
$breakpoint-XL-min: 1600px;



body {
	--rgb-accent-back: 10,70,100;
	--rgb-accent-fore: 255,255,255;
	--rgb-accent-fore-highlight: 255,255,0;

	--rgb-button-back: 243,243,243;
	--rgb-button-back-green: 221,255,204;
	--rgb-button-back-red: 255,204,204;
	--rgb-button-fore: 85,85,85;
	--rgb-button-hover-back: 208,234,255;
	--rgb-button-hover-back-green: 204,238,187;
	--rgb-button-hover-back-red: 238,187,187;
	--rgb-button-hover-fore: 0,0,0;

	--rgb-page-back: 249,249,249;

	--rgb-text-back: 255,255,255; // Classes should usually use transparent instead of this
	--rgb-text-fore: 15,15,15;

	--rgb-selection-back: 243,243,243;
	--rgb-selection-fore: 0,0,0;

	--rgb-status-back-info: 184,244,250;
	--rgb-status-back-success: 223,255,197;
	--rgb-status-back-warning: 255,216,163;
	--rgb-status-back-danger: 255,203,199;
	--rgb-status-fore: 0,0,0;

	&.eforever,
	&.eparasiempre {
		--rgb-accent-back: 70,50,110;
	}

	&.development {
		--rgb-accent-back: 68,68,68;
	}
}

/**** App Variables ****/

$table-bg-active: rgba(#000, .075);
$table-border-width: 1px;
$table-border-color: #eee;
$page-background-darker: darken(#f9f9f9, 5%);

/**** App roots ****/

$assets-root: '~/assets/';
$images-root: $assets-root + 'img/';
$fonts-root: $assets-root + 'fonts/';

/**** Tab View ****/

$tabs-tab-padding: 12px;

/**** Navbar ****/

$navbar-text-color: #fff;
$navbar-height: 50px;

/**** Menu Sidebar ****/

$menu-width-at-breakpoint-XS: 0px;
$menu-width-at-breakpoint-SM: 50px;		// collapsed css class will be added, so menu text doesn't show
$menu-width-at-breakpoint-MD: 200px;
$menu-width-at-breakpoint-LG: 215px;
$menu-width-at-breakpoint-XL: 230px;

/**** Progress ****/

$progress-border-radius: 0;
$progress-height: 20px;

/**** Fonts ****/

$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 500;
$font-bolder: 700;
$font-ultraBold: 900;

/**** Role colors ****/

$role-color-entrepreneur: #409c3d;
$role-color-mentor: #0996b8;
$role-color-facilitator: #948b4e;
$role-color-specialist: #ba7b5c;
$role-color-reader: #7089c4;
$role-color-interviewer: #967fb9;
$role-color-judge: #c1728b;

/**** Ratings ****/

$rating-1: #700;
$rating-2: lighten(#700, 65%);
$rating-3: #666;
$rating-4: lighten(#070, 65%);
$rating-5: #070;

$row-hover: rgba(var(--rgb-accent-back), 0.1);
$btn-border-color: rgba(0,0,0,0.3);