.selector-content-section {
	height: 100%;
	display: flex;

	.selector-editor-section {
		flex: 1;
		padding: 0 .3em 0 .25em;
	}
}
@media all and (max-width: 1280px) {
	.selector-content-section {
		flex-direction: column;

		.selector-editor-section {
			flex: 1;
			padding: 0 0 0 .25em;
		}
	}
}