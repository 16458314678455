html {
	min-height: 100%;
}

body {
	font-size: 14px;
	color: rgb(var(--rgb-text-fore));
	background-color: #f9f9f9;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;

	small {
		font-size: 70%;
	}
}

ul {
	padding-left: 1rem;
}

.transition {
	-webkit-transition: .3s;
	-moz-transition: .3s;
	-ms-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
}

.bottom-30 {
	margin-bottom: 30px;
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
	background-color: #FFF !important;
	-webkit-box-shadow: 0 0 0 1000px rgba(255, 255, 0, 0.25) inset !important;
	box-shadow: 0 0 0 1000px rgba(255, 255, 0, 0.25) inset !important;
}

.blockquote,
blockquote {
	position: relative;
	top: -15px;
	padding-bottom: 0;
	margin-bottom: 0;
	font-size: .8em;
	padding: .1em 0 .1em .5em;
	border-left: 3px solid rgba(0, 0, 0, .3);
}

input[type=tel]:not(.mat-mdc-input-element),
input[type=text]:not(.mat-mdc-input-element),
input:not([type]):not(.k-input-inner):not(.mat-mdc-input-element),
textarea:not(.mat-mdc-input-element) {
	border: solid 1px rgba(0, 0, 0, 0.25) !important;
	padding: 0.3em !important;
	border-radius: 4px !important;

	&:hover {
		background-color: rgb(var(--rgb-button-hover-back));
	}
}

input[type=tel]:not(.mat-mdc-input-element) {
	padding-left: 52px !important;
}
