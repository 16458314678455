.card {
	position: static;
	border: none;
	border-radius: .7rem .7rem .3rem .3rem;
	overflow: hidden;
	// margin-bottom: 0;
	-webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);

	.card-header {
		background: rgb(var(--rgb-accent-back));
		color: rgb(var(--rgb-accent-fore));
		line-height: 10px;
		border-radius: .7rem .7rem 0 0;
		border: none;

		a {
			color: rgb(var(--rgb-accent-fore));

			&:hover {
				color: rgba(var(--rgb-accent-fore), 0.8);
			}
		}
	}

	.card-footer {
		background: rgb(var(--rgb-accent-back));
		color: rgb(var(--rgb-accent-fore));
		line-height: 6px;
		border-radius: 0;
		border: none;
	}

	&.border-default {
		background: transparent;
		border: 1px solid rgb(var(--rgb-accent-back));
	}

	&.card-primary {
		background: rgb(var(--rgb-accent-back));
		color: rgb(var(--rgb-accent-fore));
	}

	&.border-primary {
		background: transparent;
		border: 1px solid rgb(var(--rgb-accent-back));
	}

	&.card-success {
		background: rgb(var(--rgb-status-back-success));
	}

	&.border-success {
		background: transparent;
		border: 1px solid rgb(var(--rgb-status-back-success));
	}

	&.card-info {
		background: rgb(var(--rgb-status-back-info));
	}

	&.border-info {
		background: transparent;
		border: 1px solid rgb(var(--rgb-status-back-info));
	}

	&.card-warning {
		background: rgb(var(--rgb-status-back-warning));

	}

	&.border-warning {
		background: transparent;
		border: 1px solid rgb(var(--rgb-status-back-warning));
	}

	&.card-danger {
		background: rgb(var(--rgb-status-back-danger));
	}

	&.border-danger {
		background: transparent;
		border: 1px solid rgb(var(--rgb-status-back-danger));
	}

	&.card-danger,
	&.card-info,
	&.card-primary,
	&.card-success,
	&.card-warning {
		.card-header {
			background: rgba(var(--rgb-text-fore), 0.4);
		}

		.card-body {
			color: rgb(var(--rgb-text-back));
		}

		.card-footer {
			background: rgba(var(--rgb-accent-back), 0.2);
		}
	}

	&.overlay {
		.card-img {
			border-radius: 0;
			-webkit-filter: brightness(100%);
			-moz-filter: brightness(100%);
			-ms-filter: brightness(100%);
			-o-filter: brightness(100%);
			filter: brightness(100%);
		}

		&:hover .card-img {
			-webkit-filter: brightness(80%);
			-moz-filter: brightness(80%);
			-ms-filter: brightness(80%);
			-o-filter: brightness(80%);
			filter: brightness(80%);
		}

		.card-img-overlay {
			color: rgb(var(--rgb-text-back));

			&.overlay-bottom {
				top: auto;
			}

			&.slide-up {
				-webkit-transform: translateY(100%);
				-moz-transform: translateY(100%);
				-ms-transform: translateY(100%);
				-o-transform: translateY(100%);
				transform: translateY(100%);
			}

			&.slide-down {
				-webkit-transform: translateY(-100%);
				-moz-transform: translateY(-100%);
				-ms-transform: translateY(-100%);
				-o-transform: translateY(-100%);
				transform: translateY(-100%);
			}

			&.slide-left {
				-webkit-transform: translateX(-100%);
				-moz-transform: translateX(-100%);
				-ms-transform: translateX(-100%);
				-o-transform: translateX(-100%);
				transform: translateX(-100%);
			}

			&.slide-right {
				-webkit-transform: translateX(100%);
				-moz-transform: translateX(100%);
				-ms-transform: translateX(100%);
				-o-transform: translateX(100%);
				transform: translateX(100%);
			}

			&.hover-opacity {
				opacity: 0;
			}
		}

		&:hover .slide-up {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			transform: translateY(0);
		}

		&:hover .slide-down {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			transform: translateY(0);
		}

		&:hover .slide-left {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
		}

		&:hover .slide-right {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
		}

		&:hover .hover-opacity {
			opacity: 1;
		}
	}
}

.card-img-top {
	border-radius: 0;
}

.card-columns {
	column-count: auto;
	column-width: 20rem;
	-webkit-column-fill: balance !important;
	column-fill: balance !important;
	height: auto !important;
	width: 100%;
}

.card-columns-25 {
	column-count: auto;
	column-width: 25rem;
	-webkit-column-fill: balance !important;
	column-fill: balance !important;
	height: auto !important;
	width: 100%;
}

// @media (max-width: 849px) {
// 	.card-columns {
// 		-webkit-column-count: 1 !important;
// 		column-count: 1 !important;
// 	}
// }

// @media (max-width: 1199px) and (min-width: 850px) {
// 	.card-columns {
// 		-webkit-column-count: 2 !important;
// 		column-count: 2 !important;
// 	}
// }

// @media (max-width: 1599px) and (min-width: 1200px) {
// 	.card-columns {
// 		-webkit-column-count: 3 !important;
// 		column-count: 3 !important;
// 	}
// }

/**
 * The widget-controls class appears to always be nested in cards.
 * The name is poor because it's not obvious what it is for.
 * Consider renaming or removing.
 */
.widget-controls {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	padding: 10px 20px;
	font-size: 14px;

	a {
		color: rgb(var(--rgb-accent-fore));
		margin-left: 7px;
		line-height: 1;
		vertical-align: top;
		display: inline-block;

		&:hover {
			color: rgba(var(--rgb-accent-fore), 0.7);
		}

		&.dropdown-toggle:after {
			display: none;
		}
	}

	ul.dropdown-menu {
		min-width: 9rem;
		padding: 0;
		border-radius: 0;

		li {
			padding: 4px;
			overflow: hidden;

			a {
				color: rgb(var(--rgb-text-fore));
				font-size: 13px;
				width: 100%;
			}

			&:hover {
				background-color: rgb(var(--rgb-accent-back));
			}

			&:hover a {
				color: rgb(var(--rgb-accent-fore));
			}
		}
	}

}
