@import "material/app-material-dialog";
@import "material/app-accordion-simple";
@import "material/app-menu";
@import "material/app-navigation-tabs";

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]).cdk-keyboard-focused,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]).cdk-program-focused,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
	background: $row-hover;
}

.mat-expansion-indicator {
	transition: transform .6s;
}

mat-accordion.grid-accordion {
	display: block;
	border: 1px solid rgba(0, 0, 0, 0.12) !important;
	border-radius: 2px;

	& > mat-expansion-panel {
		background-color: transparent;
		border-radius: 0 !important;
		border: none !important;
		box-shadow: inset 0 0 0 rgba(0, 0, 0, 0) !important;
		transition: all .3s ease;
		margin: 0;

		&:not(:last-child) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
		}

		mat-expansion-panel-header {
			background-color: transparent;
			transition: all .3s ease;

			&.mat-expanded,
			&.mat-expanded,
			&.mat-expanded:focus {
				margin: .3em .3em 1em;
				padding: 0 1.2em;
				background-color: #3691;
			}
		}
	}
}

[mat-icon-button] {
	cursor: pointer;
}

/* Menu Items */
.mat-menu-item {
	height: 42px !important;
	line-height: 42px !important;

	&.separator {
		border-top: 1px solid $btn-border-color;
	}

	&.separator-bottom {
		border-bottom: 1px solid $btn-border-color;
	}

	i {
		align-content: center;

		&.fad,
		&.fal,
		&.far,
		&.fas {
			width: 1.5em;
			margin-right: .5em;
		}
	}
}

/* Mat Select */
.mat-form-field-flex:hover {

	.mat-form-field-appearance-outline,
	.mat-form-field-outline-thick {
		color: rgba(0, 0, 0, 0.12) !important;
		background-color: rgb(var(--rgb-button-hover-back));
	}
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
	.mat-pseudo-checkbox {
		display: none;
	}
}

.mat-pseudo-checkbox-checked {
	background: rgb(var(--rgb-accent-back));
	color: rgb(var(--rgb-accent-fore));
}

.mat-pseudo-checkbox-checked::after {
	top: 1px !important;
	left: 1px !important;
	width: 10px !important;
	height: 6px !important;
	border-left: 2px solid rgb(var(--rgb-accent-fore)) !important;
	transform: rotate(-50deg) !important;
	opacity: 1 !important;
}

.mat-expansion-panel-header-title{
	gap: 1em;
}

// For some stupid reason, material dialogs force a max-width of 80vw.  This overrides that.
// See https://stackoverflow.com/questions/46034619/angular-material-2-how-to-overwrite-dialog-max-width-to-100vw
.full-width-mat-dialog {
	max-height: 98vh !important;
	max-width: 98vw !important;
}