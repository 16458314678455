@keyframes btn-spin-animation {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.btn:not([disabled]):hover {
	.spinner {
		color: rgba(0, 0, 0, 0.5) !important;
	}
}

/*Buttons*/
.btn {
	border-radius: .2rem;
	color: rgb(var(--rgb-button-fore));
	border-color: rgba(0, 0, 0, 0.2);
	background: rgb(var(--rgb-button-back));
	transition: color .1s, background-color .5s, transform .1s, box-shadow .1s;
	margin-left: 3px;
	margin-right: 3px;
	font-size: 1em;
	padding: 3px 7px;

	&:not([disabled]) {
		box-shadow: .05rem .05rem .1rem rgba(0, 0, 0, 0.2);

		&.btn-green {
			background-color: rgb(var(--rgb-button-back-green));
		}

		&.btn-red {
			background-color: rgb(var(--rgb-button-back-red));
		}
	}

	&:hover:not([disabled]) {
		border-color: rgba(0, 0, 0, 0.35);
		cursor: pointer;
		transform-origin: center center;
		transform: scale(1.05) translate(-0.05rem, -0.05rem);
		box-shadow: .1rem .1rem .3rem rgba(0, 0, 0, 0.3);
		color: rgb(var(--rgb-button-hover-fore));
		background-color: rgb(var(--rgb-button-hover-back));

		> * {
			color: rgb(var(--rgb-button-hover-fore));
		}

		&.btn-action,
		&.btn-green {
			background-color: rgb(var(--rgb-button-hover-back-green));
		}

		&.btn-caution,
		&.btn-red {
			background-color: rgb(var(--rgb-button-hover-back-red));
		}
	}

	&:focus {
		outline: 0;
		color: #000;
		border-color: rgba(0, 0, 0, 0.35);
	}

	&:active {
		outline: 0 !important;
		transition: transform .1s, box-shadow .1s;
		transform: scale(0.98) !important;
		box-shadow: 0 0 0 rgb(0, 0, 0) !important;
	}

	&.toolbar-btn {
		height: 2em;
		line-height: 1em;
	}

	&.grid-btn {
		height: 1.8em;
		line-height: 1.2em;
		width: 28px;
		margin-top: 1px;
		margin-left: 1px;
		margin-right: 0;
		padding: 0 4px;
	}

	.me-icon-part {
		// margin: 0 -6px 0 -3px;  // Commented out because the icon was getting overflow hidden on the left of the droplists in pre-accelerator page
		display: inline-grid !important;

		.me-icon.fa-stack {
			height: 1em !important;
			line-height: 1em !important;
			margin-bottom: 4px;
			top: 2px;
		}

		.me-icon-text {
			color: #333 !important;
		}
	}

	&:before {
		font-family: 'Font Awesome 5 Pro';
		font-weight: 900;
		content: none;
		opacity: 0;
		margin-right: 0;
		width: 0;
		-webkit-transform: scale(0%);
		transform: scale(0%);
		-webkit-transition: margin-right .25s, opacity .25s, width .25s, transform .25s;
		-moz-transition: margin-right .25s, opacity .25s, width .25s, transform .25s;
		-o-transition: margin-right .25s, opacity .25s, width .25s, transform .25s;
		transition: margin-right .25s, opacity .25s, width .25s, transform .25s;
		-webkit-animation: btn-spin-animation .8s infinite linear;
		animation: btn-spin-animation .8s infinite linear;
	}

	&.btn-spinning {
		&:before {
			content: "\f3f4";
			display: inline-block;
			opacity: .7;
			margin-right: .4em;
			width: 1em;
			-webkit-transform: scale(100%);
			transform: scale(100%);
		}
	}
}

.btn[disabled] {
	border-color: rgba(0, 0, 0, 0.1);
	background-color: rgba(0, 0, 0, 0.01);
	color: rgba(0, 0, 0, 0.5);
}

.btn-x {
	border: solid 1px transparent;
	padding: 0 0.3em;
	margin: 0 -0.4em 0 0;
	border-radius: 5px;
	background-color: transparent;
	font-size: 0.7em;
	position: relative;
	top: -1px;

	&:hover,
	&:active {
		background-color: rgba(0, 0, 0, 0.05);
	}

	&:focus {
		border-color: rgba(0, 0, 0, 0.01);
	}
}

.k-button:hover {
	background-image: none !important;
}

.text-btn {
	display: inline-block;
	border-radius: .2rem;
	color: inherit;
	border-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 0.2);
	background: rgba(191, 191, 191, 0.1);
	transition: color .1s, border-color .5s, background-color .5s, transform .1s, box-shadow .1s;
	margin: calc(-.2em + -1px);
	font-size: 1em;
	padding: 0 .2em;
	border: solid 1px rgba(0, 0, 0, 0.1);
	border-color: rgba(255, 255, 255, 1) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 1);
	box-shadow: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&[disabled] {
		color: rgba(var(--rgb-button-fore), 0.85);
		cursor: no-drop;
	}

	&:not([disabled]) {

		&.btn-green {
			background-color: rgb(var(--rgb-button-back-green));
		}

		&.btn-red {
			background-color: rgb(var(--rgb-button-back-red));
		}
	}

	&:hover:not([disabled]) {
		box-shadow: .05rem .05rem .1rem rgba(0, 0, 0, 0.2);
		border-color: rgba(0, 0, 0, 0.35);
		cursor: pointer;
		box-shadow: 0 0 .1rem rgba(0, 0, 0, 0.3);
		color: rgb(var(--rgb-button-hover-fore));
		background-color: rgb(var(--rgb-button-hover-back));
		z-index: 100;

		> * {
			color: rgb(var(--rgb-button-hover-fore));
		}

		&.btn-action,
		&.btn-green {
			background-color: rgb(var(--rgb-button-hover-back-green));
		}

		&.btn-caution,
		&.btn-red {
			background-color: rgb(var(--rgb-button-hover-back-red));
		}
	}

	&:focus {
		outline: 0;
		color: #000;
		border-color: rgba(0, 0, 0, 0.35);
	}

	&:active:not([disabled]) {
		outline: 0 !important;
		transition: transform .1s, box-shadow .1s;
		transform: scale(0.98) !important;
		box-shadow: 0 0 0 rgb(0, 0, 0) !important;
	}

	&.toolbar-btn {
		height: 2em;
		line-height: 1em;
	}

	&.grid-btn {
		height: 1.8em;
		line-height: 1.2em;
		width: 28px;
		margin-top: 1px;
		margin-left: 1px;
		margin-right: 0;
		padding: 0 4px;
	}

	.me-icon-part {
		margin: 0 -6px 0 -3px;
		display: inline-grid !important;

		.me-icon.fa-stack {
			height: 1em !important;
			line-height: 1em !important;
			margin-bottom: 4px;
			top: 2px;
		}

		.me-icon-text {
			color: #333 !important;
		}
	}

	&:before {
		font-family: 'Font Awesome 5 Pro';
		font-weight: 900;
		content: "\f3f4";
		display: inline-block;
		opacity: 0;
		margin-right: 0;
		width: 0;
		-webkit-transform: scale(0%);
		transform: scale(0%);
		-webkit-transition: margin-right .25s, opacity .25s, width .25s, transform .25s;
		-moz-transition: margin-right .25s, opacity .25s, width .25s, transform .25s;
		-o-transition: margin-right .25s, opacity .25s, width .25s, transform .25s;
		transition: margin-right .25s, opacity .25s, width .25s, transform .25s;
		-webkit-animation: btn-spin-animation .8s infinite linear;
		animation: btn-spin-animation .8s infinite linear;
	}

	&.btn-spinning {
		&:before {
			opacity: .7;
			margin-right: .4em;
			width: 1em;
			-webkit-transform: scale(100%);
			transform: scale(100%);
		}
	}
}

.text-btn + .text-btn {
	margin-left: 0;
}

.text-btn.border-red:not(:hover) {
	border: solid 1px #911;
	background-color: #FFE;
	;
}

.text-btn.text-btn-not-inline {
	padding: 0 .5em;
	margin: 0;
}

.text-btn.text-btn-not-inline + .text-btn.text-btn-not-inline {
	margin-left: .1em;
}

.text-btn.text-btn-not-inline-flat {
	padding: 0 .5em;
	margin: 0;
	border-color: transparent;
	background-color: transparent;

	&:focus {
		border-color: rgba(0, 0, 0, 0.2);
	}
}

.text-btn.text-btn-not-inline-flat + .text-btn.text-btn-not-inline-flat {
	margin-left: .1em;
}