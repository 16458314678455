@import 'app-variables';

$tab-strip-border-color: #ccc;

kendo-tabstrip.k-tabstrip {

	.k-tabstrip-content,
	.k-tabstrip > .k-content {
		padding: $tabs-tab-padding;
		border-color: $tab-strip-border-color;

		border-radius: 0;
		overflow: auto;
		// Don't change this!
	}

	.k-tabstrip-items-wrapper {
		border-color: $tab-strip-border-color;
		margin-bottom: initial !important;
		z-index: initial !important;

		.k-item {
			margin-bottom: 0;

			&.k-active {
				border-bottom-color: transparent !important;
			}
		}
	}

	ul.k-tabstrip-items {
		border-color: $tab-strip-border-color;
		position: relative;
		top: 1px;
	}

	.k-tabstrip-items li.k-item {
		padding-left: .5em;
		padding-right: .5em;
		color: rgba(0, 0, 0, 0.87);
		border-top-right-radius: 16px !important;
		border-top-left-radius: 4px !important;

		span.k-link {
			padding: 5px 4px 5px 3px;
		}

		&.k-active {
			border-color: $tab-strip-border-color;

			span {
				color: #000;
			}
		}

		&:hover:not(.k-active) {
			border: solid 1px rgba(0, 0, 0, 0.1) !important;
			border-bottom: solid 1px $tab-strip-border-color !important;
		}

		&:focus {
			box-shadow: none;

			span {
				text-shadow: 0 0 black;
			}
		}
	}


	&.tabs-only {
		&.k-tabstrip > div.k-content {
			padding: 10px 0;
			border: none;
		}
	}


	&.tabs-have-page-background {

		.k-tabstrip-items .k-item.k-active,
		.k-tabstrip-items .k-item.k-selected {
			background-color: rgb(var(--rgb-page-back));
			border-bottom-color: transparent !important;
		}

		& > .k-content {
			background-color: rgb(var(--rgb-page-back));
		}
	}
}



.dialog {
	&.k-tabstrip > div.k-content {
		// height: 100%;

		max-height: 500px;
		min-height: 500px;
	}
}