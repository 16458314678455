a:focus,
a:hover {
	text-decoration: none;
}

a.link {
	color: #007bff;

	&:hover {
		cursor: pointer;
	}
}

* {
	outline: none !important;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
}

th {
	font-weight: bold;
}

.bg-success {
	background-color: rgb(var(--rgb-status-back-success)) !important;
}

.bg-danger {
	background-color: rgb(var(--rgb-status-back-danger)) !important;
}

.alert {
	border: solid 2px rgba(0,0,0,0.4);
	color: rgb(var(--rgb-status-fore)) !important;
	box-shadow: .3em .3em 1em rgba(0,0,0,0.4);

	&.alert-success {
		background-color: rgb(var(--rgb-status-back-success));
	}

	&.alert-info {
		background-color: rgb(var(--rgb-status-back-info));
	}

	&.alert-warning {
		background-color: rgb(var(--rgb-status-back-warning));
	}

	&.alert-danger {
		background-color: rgb(var(--rgb-status-back-danger));
	}
}

.dropdown-menu {
	padding-top: 0;
	padding-bottom: 0;
	border-radius: 0;
	font-size: 14px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);

	a {
		color: rgb(var(--rgb-text-fore));

		&:hover {
			color: rgb(var(--rgb-accent-fore));
			background-color: rgb(var(--rgb-accent-back));
		}
	}

	&:before {
		content: " ";
		position: absolute;
		top: -12px;
		left: 30px;
		display: block;
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-bottom-color: #fff;
	}
}

.dropdown-divider {
	margin: .2rem 0;
}

p:last-child {
	margin-bottom: 0 !important;
}

.h1,
h1 {
	font-size: .5rem;

	&::before {
		content: "Use Two Hashes (##) in Markdown, Not One (#)";
		font-size: 3em;
		color: red;
		font-weight: bold;
		font-style: italic;
	}
}

.h2,
.h3,
.h4,
.h5,
h2,
h3,
h4,
h5 {
	font-size: 1.25rem;
	margin-bottom: 20px;
}

.h6,
h6 {
	font-size: 1.1rem;
	margin-bottom: 10px;
}
