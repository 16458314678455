/****** Reds ******/

.color-red {
	color: red
}

.color-darkred {
	color: darkred
}


/****** Oranges ******/

.color-darkorange {
	color: darkorange
}


/****** Grays ******/

.color-lightgray {
	color: #AAA
}

.color-gray {
	color: gray
}

.color-darkgray {
	color: #666;
}


/****** Greens ******/

.color-green {
	color: green
}

.color-darkgreen {
	color: darkgreen
}


/****** Gold ******/
.color-darkgold {
	color: darkgoldenrod;
}

/****** Named ******/

.color-icon-slash {
	color: Tomato;
}

.color-redflag {
	color: #911
}