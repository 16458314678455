/**
	Display a simple HTML table with styling. Simply add these classes to the table element

	pro-table - styles the table with border lines, alternating rows, a header, and row hover colors
	pro-table-no-header - Hides the thead if there and makes changes the alternating row to start on the first row
	pro-table-with-labels - The first td of each row is right aligned with a : and without a right border

	<table class='pro-table pro-table-no-header pro-table-with-labels'>
		<tr>
			<td>Label</td>
			<td>Field</td>
		</tr>
	</table>
**/

$pro-table-header-bg: transparent;
$pro-table-header-line: solid 1px #bbb;
$pro-table-row-line: solid 1px rgba(0, 0, 0, 0.03);
$pro-table-row-bg: rgb(var(--rgb-page-back));
$pro-table-row-hover-bg: #f6f6f6;
$pro-table-row-alt-bg: transparent;
$pro-table-row-alt-hover-bg: #f6f6f6;
$pro-table-cell-color: rgba(0, 0, 0, 0.7);

.pro-table {
	border-collapse: collapse;
	user-select: none;
	margin: 2px 5px 5px 2px;
	border: solid 1px rgba(0, 0, 0, 0.1);

	tr {
		color: $pro-table-cell-color;
		font-weight: normal;

		td,
		th {
			padding: 0.4em;
			text-align: left;
			vertical-align: middle;
			border-right: $pro-table-row-line;
			line-height: 1em;

			&:last-child {
				border-right: none;
			}

			> label {
				margin-bottom: 0;
			}
		}

		th {
			color: $pro-table-cell-color;
			background: #eee;
			font-weight: bold;
			border-bottom: solid 1px rgba(0, 0, 0, 0.1);
		}

		td {
			background: $pro-table-row-bg;
			padding: 0.4em;
			height: 2em;

			/* Note: height works like min-height on TDs. It will still grow if content is larger. */
			&[colspan] {
				padding: 13px;
			}

			p,
			pro-buttons {
				margin: 0;
			}

			input,
			kendo-dropdownlist,
			textarea {
				width: 100%;
			}
		}

		&:hover {
			td {
				background: $pro-table-row-hover-bg;
			}
		}

		&:nth-child(odd) {
			td {
				background: $pro-table-row-alt-bg;
			}

			&:hover {
				td {
					background: darken($pro-table-row-hover-bg, 0.1);
				}
			}
		}
	}

	tr:not(:last-child) {
		border-bottom: $pro-table-row-line;
	}

	&.pro-table-no-header {
		thead {
			display: none;
		}

		tr {
			border-left: solid 1px rgba(0, 0, 0, 0.1);

			&:focus-within,
			&:hover {
				border-left: solid 1px rgba(0, 0, 0, 0.3);
			}

			&:nth-child(odd) {
				td {
					background: $pro-table-row-alt-bg;
				}

				&:hover {
					td {
						background: $pro-table-row-hover-bg;
					}
				}
			}

			&:nth-child(even) {
				td {
					background: $pro-table-row-bg;
				}

				&:hover {
					td {
						background: $pro-table-row-hover-bg;
					}
				}
			}
		}
	}

	&.pro-table-with-labels {
		tr {
			td:first-child:not([colspan]) {
				border-right: none;
				text-align: right;
				white-space: nowrap;

				&:after {
					content: ":";
				}

				&.pro-table-label-error {
					color: red;
				}
			}
		}
	}

	&.pro-table-without-border {
		margin: 0;
		border: none;

		&.pro-table-no-header {
			thead {
				display: none;
			}

			tr {
				border-left: solid 1px transparent;

			}
		}
	}

	&.pro-table-with-thin-rows tr td,
	&.pro-table-with-thin-rows tr th {
		padding: 0.2em;
		height: 1.3em;
	}

	.missing-value {
		font-style: italic;
		font-size: .8em;
		opacity: .7;
	}
}