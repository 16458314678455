.mat-menu-content {
	padding-top: 0 !important;
	padding-bottom: 0 !important;

	[mat-menu-header] {
		font-size: 12px;
		color: rgb(var(--rgb-accent-fore));
		background-color: rgb(var(--rgb-accent-back));
		padding: 2px 0;
		text-align: center;
	}

	[mat-menu-item].separator {
		border-top: solid 1px rgba(0,0,0,0.15);
	}

	[mat-menu-item] {
		i.fab,
		i.fal,
		i.far,
		i.fas {
			color: rgb(var(--rgb-accent-back));
			position: relative;
			left: -3px;
		}

		::ng-deep .mat-radio-outer-circle {
			border-color: rgba(0,0,0,0.07) !important;
		}

		::ng-deep .mat-radio-outer-circle:hover {
			border-color: rgba(0,0,0,0.2) !important;
		}

		.no-manual {
			color: gray !important;

			::ng-deep .mat-radio-inner-circle {
				background-color: #AAA !important;
			}

		}

		.consider {
			color: darkblue !important;

			::ng-deep .mat-radio-inner-circle {
				background-color: darkblue !important;
			}

			&.mat-radio-checked::ng-deep .mat-radio-outer-circle {
				border-color: darkblue !important;
			}
		}

		.approved {
			color: darkgreen !important;

			::ng-deep .mat-radio-inner-circle {
				background-color: darkgreen !important;
			}

			&.mat-radio-checked::ng-deep .mat-radio-outer-circle {
				border-color: darkgreen !important;
			}

		}

		.not-approved {
			color: darkred !important;

			::ng-deep .mat-radio-inner-circle {
				background-color: darkred !important;
			}

			&.mat-radio-checked::ng-deep .mat-radio-outer-circle {
				border-color: darkred !important;
			}

		}
	}
}
