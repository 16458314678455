.k-dropdown .k-dropdown-wrap {
	background-image: none;
	background-color: #fff;

	&:hover {
		background-image: none;
		background-color: #fff;

	}
}

.k-textbox[readonly] {
	background-color: transparent;
	border: transparent;
}

.k-datepicker.has-error {
	border-color: darkred;

	.k-dropdown-wrap,
	.k-picker-wrap {
		border-color: darkred;
	}
}


.k-tooltip-validation {
	color: darkred;
}

::placeholder {
	color: rgba(0, 0, 0, 0.2) !important;
}