$kendo-color-primary: rgb(10, 70, 100);
// $kendo-color-primary-contrast: purple;
// $kendo-color-secondary: yellow;
// $kendo-color-secondary-contrast: orange;

$kendo-grid-selected-bg: $kendo-color-primary;
$kendo-grid-selected-text: white;

@import "@progress/kendo-theme-default/scss/all";

@import "app-kendo-combobox";
@import "app-kendo-dropdown-button";
@import "app-kendo-dropdownlist";
@import "app-kendo-input";
@import "app-kendo-masked-textbox";
@import "app-kendo-notification";
@import "app-kendo-panelbar";
@import "app-kendo-popup";
@import "app-kendo-switch";
@import "app-kendo-grid";
@import "app-kendo-tabstrip";
@import "app-kendo-scheduler";
