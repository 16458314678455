::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

::-webkit-scrollbar-button {
	width: 0;
	height: 0;
}

::-webkit-scrollbar-thumb {
	background: #CCC;
	border: 1px solid rgb(var(--rgb-page-back));
	border-left: 2px solid rgb(var(--rgb-page-back));
	border-right: 2px solid rgb(var(--rgb-page-back));
	border-radius: 2px;
	border-radius: 3px;

	&:active,
	&:hover {
		background: rgb(var(--rgb-accent-back));
	}

	&:active {
		border-left: 1px solid rgb(var(--rgb-page-back));
		border-right: 1px solid rgb(var(--rgb-page-back));
	}
}

::-webkit-scrollbar-track {
	background: #CCC;
	border: 5px solid rgb(var(--rgb-page-back));
	border-radius: 0;

	&:active {
		background: rgb(var(--rgb-accent-back));
	}
}

::-webkit-scrollbar-corner {
	background: transparent;
}

.menu-scrollbar {
	overflow: auto;
	height: calc(100% - 61px);

	&::-webkit-scrollbar-thumb {
		background: rgba(var(--rgb-accent-fore), 0.2);
		border: 1px solid rgb(var(--rgb-accent-back));
		border-left: 3px solid rgb(var(--rgb-accent-back));
		border-right: 3px solid rgb(var(--rgb-accent-back));
		border-radius: 2px;
		border-radius: 3px;

		&:active,
		&:hover {
			background: rgb(var(--rgb-accent-fore));
		}

		&:active {
			border-left: 2px solid rgb(var(--rgb-accent-back));
			border-right: 2px solid rgb(var(--rgb-accent-back));
		}
	}

	&::-webkit-scrollbar-track {
		background: rgba(var(--rgb-accent-fore), 0.2);
		border: 5px solid rgb(var(--rgb-accent-back));
		border-radius: 0;

		&:active {
			background: rgb(var(--rgb-accent-fore));
		}
	}
}
