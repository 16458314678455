@import "app-variables";

::ng-deep .mat-checkbox-checked {
	div.mat-checkbox-background {
		background-color: rgb(var(--rgb-accent-back)) !important;
		color: rgb(var(--rgb-accent-fore)) !important;
	}
}

::ng-deep .mat-checkbox-checked.mat-checkbox-disabled {
	div.mat-checkbox-background {
		background-color: #CCC !important;
	}
}

::ng-deep div.mat-checkbox-frame {
	border-color: #CCC !important;
	border-width: 1px;
}

h5 {
	margin-bottom: 20px;
}

.bad-email {
	color: darkred;
}

mat-error {
	text-align: right;
}

mat-hint {
	width: 100%;
	text-align: right;
	float: initial !important;
	color: rgba(0, 0, 0, 0.6);
}

::ng-deep .mat-input-placeholder,
::ng-deep .mat-select-placeholder {
	font-size: 15px !important;
	color: rgba(0, 0, 0, 0.5);
}

mat-form-field {
	margin-top: 1em;
	max-width: 100% !important;
}

::ng-deep .intl-phone label.mat-input-placeholder {
	transform: perspective(100px) translateX(45px);
}

::ng-deep .intl-tel-input.allow-dropdown {

	width: 100%;

	.selected-flag {
		padding: 0;
		width: 38px;
		margin-left: 7px;
	}
}

textarea.street-address {
	min-height: 30px;
}

textarea.full-address {
	min-height: 85px;
}

/* Remove the pale yello background that chrome adds to auto-filled fields */
input:-webkit-autofill,
textarea:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px #e9ebee inset;
}

.mat-menu-item:hover:not([disabled]) {
	background: $row-hover;
}

.mat-form-field-infix {
	border-top: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: .5em 0;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: rgb(var(--rgb-accent-back));
}

::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: rgb(var(--rgb-accent-back));
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
	background-color: rgba(var(--rgb-accent-back), 0.2);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-group {
	background: unset !important;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
	height: 32px;
	line-height: 32px;
	border: solid 1px #DDD !important;

	.mat-button-toggle-appearance-standard.mat-button-toggle {
		color: black;
		background-color: rgba(0, 0, 0, 0.05);

		&.mat-button-toggle-checked {
			color: rgb(var(--rgb-accent-fore));
			background-color: rgb(var(--rgb-accent-back));
		}

		.mat-button-toggle-button {
			height: 32px;
			line-height: 32px;

			.mat-button-toggle-label-content {
				padding: 0 16px !important;
				height: 32px;
				line-height: 32px;
				position: relative;
				top: -2px;
			}
		}
	}

	.mat-button-toggle-appearance-standard.mat-button-toggle:not(:first) {
		border-left: solid 2px rgb(var(--rgb-accent-back)) !important;
	}
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background-color: #fafafa;
}

.mat-mdc-form-field-infix {
	padding-top: 4px !important;
	padding-bottom: 4px !important;
	min-height: unset !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field{
	padding-left: 4px	!important;
}
